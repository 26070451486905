<template>
  <v-container>
    <v-app-bar dark app color="primary">
      <v-btn icon>
        <v-icon>mdi-puzzle</v-icon>
      </v-btn>
      <v-toolbar-title>Anmeldungen</v-toolbar-title>
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Suche (Name, Klasse)"
        single-line
        hide-details
        clearable
        id="search"
      ></v-text-field>
      <template v-slot:extension>
        <v-tabs align-with-title>
          <v-tabs-slider color="error"></v-tabs-slider>
          <v-tab :to="{ name: 'OptionalAvailability' }"> Freifächer </v-tab>
          <v-tab :to="{ name: 'SciencePlusAvailability' }"> NW+ </v-tab
          ><!-- TODO: NeXT-->
          <v-tab :to="{ name: 'OptionalChoice' }"> Wahl </v-tab>
          <v-tab :to="{ name: 'OptionalSettings' }"> Einstellungen </v-tab>
          <v-tab :to="{ name: 'OptionalStatistics' }"> Statistik </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
    <router-view :search="search" />
  </v-container>
</template>

<script>
export default {
  name: 'Optional',
  data() {
    return {
      search: '',
    };
  },
};
</script>
